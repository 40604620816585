import { useState } from "react";
import AddChargerButton from "../AddChargerButton/AddChargerButton";
interface Props {
  activeTab: string;
  setActiveTab: (value: string) => void;
  setAddCharger: () => void;
}
const NavBar = ({ activeTab, setActiveTab, setAddCharger }: Props) => {
  return (
    <div className="fixed bg-white bottom-0 left-0 right-0 flex justify-between border-t-[2px] border-[#F7F7F7] px-5 pb-5 pt-2">
      <div
        onClick={() => setActiveTab("home")}
        className={`flex bg- flex-col p-1 text-[10px] font-semibold items-center justify-center gap-1 ${
          activeTab === "home" ? "text-light-green" : "text-dark-green"
        }`}
      >
        <img
          src={
            activeTab === "home"
              ? "/assets/svg/navbar/homeActive.svg"
              : "/assets/svg/navbar/home.svg"
          }
          alt="home"
        />
        Home
      </div>
      <div
        onClick={() => setActiveTab("chargers")}
        className={`flex flex-col p-1 text-[10px] font-semibold items-center justify-center gap-1 ${
          activeTab === "chargers" ? "text-light-green" : "text-dark-green"
        }`}
      >
        <img
          src={
            activeTab === "chargers"
              ? "/assets/svg/navbar/chargersActive.svg"
              : "/assets/svg/navbar/chargers.svg"
          }
          alt="charger"
        />
        Chargers list
      </div>
      <div
        onClick={() => setActiveTab("history")}
        className={`flex flex-col p-1 text-[10px] font-semibold items-center justify-center gap-1 ${
          activeTab === "history" ? "text-light-green" : "text-dark-green"
        }`}
      >
        <img
          src={
            activeTab === "history"
              ? "/assets/svg/navbar/sessionsActive.svg"
              : "/assets/svg/navbar/sessions.svg"
          }
          alt="history"
        />
        Sessions history
      </div>
      <div
        onClick={() => setActiveTab("profile")}
        className={`flex flex-col p-1 text-[10px] font-semibold items-center justify-center gap-1 ${
          activeTab === "profile" ? "text-light-green" : "text-dark-green"
        }`}
      >
        <img
          src={
            activeTab === "profile"
              ? "/assets/svg/navbar/profileActive.svg"
              : "/assets/svg/navbar/profile.svg"
          }
          alt="profile"
        />
        Profile
      </div>
      {activeTab === "chargers" && (
        <div className="absolute right-6 bottom-[110px]">
          <AddChargerButton handleClick={setAddCharger} />
        </div>
      )}
    </div>
  );
};
export default NavBar;
