import { makeAutoObservable, runInAction } from "mobx";
import { ChargerDto, ChargersStatusCountDto } from "../models/charger";
import agent from "../api/agent";
import { roundOnFixedDecimals } from "../../utils/numbers/numbersHelper";

export default class ChargerStore {
    chargersStatusCount: ChargersStatusCountDto | undefined;
    chargers: ChargerDto[] | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    getChargersStatusCount = async () => {
        try {
            const res = await agent.Charger.chargersStatusCount();
            runInAction(() => this.chargersStatusCount = res);
        } catch (err) {
            console.log("Error while fetching connector status count: ", err);
        }
    };

    getChargers = async () => {
        try {
            const res = await agent.Charger.chargers();
            const mappedRes = res.map((el: any) => ({
                ...el,
                longitude: roundOnFixedDecimals(el.longitude, 3),
                latitude: roundOnFixedDecimals(el.latitude, 3),
            }));
            runInAction(() => this.chargers = mappedRes);
        } catch (err) {
            console.log("Error while fetching chargers: ", err);
        }
    };

    deleteCharger = async (id: number) => {
        try {
            await agent.Charger.deleteCharger(id);
            return true;
        } catch (err) {
            console.log("Error while deleting charger: ", err);
            return false;
        }
    };

    addCharger = async (id: string) => {
        try {
            await agent.Charger.addCharger(id);
            return true;
        } catch (err) {
            console.log("Error while adding charger: ", err);
            return false;
        }
    };
}