import { Navigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../stores/store";

interface ProtectedRouteProps {
  element: JSX.Element;
}

const ProtectedRoute = observer(({ element }: ProtectedRouteProps) => {
  const { commonStore } = useStore();

  return commonStore.isLoggedIn() ? element : <Navigate to="/login" replace />;
});

export default ProtectedRoute;
