import { useEffect, useState } from "react";

interface Props {
  text: string;
  positive: boolean;
  onClose: () => void;
}

const Toast = ({ text, positive, onClose }: Props) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 2200);

    return () => clearTimeout(timer);
  }, [onClose]);

  if (!isVisible) {
    return null;
  }
  return (
    <>
      {" "}
      <div
        aria-hidden="true"
        className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-[1px] z-9999"
      ></div>
      <div className="flex gap-2 rounded-[8px] items-center fixed py-6 px-6 z-1000 bg-white top-0 left-0 right-0 w-full">
        <img
          src={
            positive
              ? "/assets/svg/toast/positive.svg"
              : "/assets/svg/toast/negative.svg"
          }
          alt="status"
        />
        <p className="text-[12px] font-semibold text-[#000]">{text}</p>
      </div>
    </>
  );
};

export default Toast;
