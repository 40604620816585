import { ChargerDto } from "../../app/models/charger";


interface Props {
  charger: ChargerDto;
}

const Charger = ({ charger }: Props) => {

  return (
    <div className="flex relative flex-col justify-between border-2 rounded-[5px] border-[#E6E6E6] py-3 px-3">
      <div className="flex px-2.5 py-1.5 rounded-[10px] mb-3 bg-[#F7F7F7] border-2 border-[#F7F7F7] items-center justify-between text-[16px] font-semibold text-dark-green">
        {charger.chargerInformation}
      </div>

      <div className="flex justify-between border-b-2 border-[#F7F7F7] py-2.5">
        <p className="text-[12px] font-semibold text-darkgreen">Charger ID</p>
        <p className="text-[12px] font-semibold text-darkgreen">
          {charger.ocppChargerId}
        </p>
      </div>
      <div className="flex justify-between border-b-2 border-[#F7F7F7] py-2.5">
        <p className="text-[12px] font-semibold text-darkgreen">Max Power</p>
        <p className="text-[12px] font-semibold text-darkgreen">
          {charger.maxPower} kW
        </p>
      </div>
      <div className="border-b-2 border-[#F7F7F7] py-2.5">
        <div className="flex justify-between">
          <p className="text-[12px] font-semibold text-darkgreen">Location</p>
          <p className="text-[12px] font-semibold text-darkgreen">{`(${charger.latitude}),(${charger.longitude})`}</p>
        </div>
      </div>
      <div className="border-b-2 border-[#F7F7F7] py-2.5">
        <div className="flex justify-between">
          <p className="text-[12px] font-semibold text-darkgreen">
            Number of connectors
          </p>
          <p className="text-[12px] font-semibold text-darkgreen">
            {charger.numberOfConnectors}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Charger;
