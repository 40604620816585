interface Props {
  handleClose: () => void;
  forgot?: true;
  email?: string;
}

const Popup = ({ handleClose, forgot, email }: Props) => {
  const text = forgot
    ? `We sent you an email to ${email} with  instructions how to reset your password`
    : "We sent you an email to confirm it is you. Please find it and finish registration from there.";
  return (
    <>
      <div
        aria-hidden="true"
        className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-[1px] z-0"
      ></div>
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div className="relative rounded-[10px] w-[320px] bg-white pt-14">
          <img
            className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "
            src="/assets/svg/circle/circle.svg"
            alt=""
          />
          <div className="flex flex-col gap-3 px-4 text-center">
            <p className="text-[18px] font-semibold text-dark-green">
              Check your E-mail
            </p>
            <p className="text-[12px] text-[#ADADAD]">{text}</p>
          </div>

          <button
            onClick={handleClose}
            className="py-3 mt-5 w-full text-[12px] text-light-gray font-semibold  rounded-b-[10px] bg-[#F7F7F7]"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
};

export default Popup;
