import { makeAutoObservable, runInAction } from "mobx";
import { LoginUserDto, RegisterUserDto, User } from "../models/user";
import agent from "../api/agent";
import { store } from "./store";

export default class UserStore {
  user: User | null = null;
  showSplashScreen = true;

  constructor() {
    makeAutoObservable(this);
  }

  login = async (creds: LoginUserDto) => {
    const result: { userId: number; token: string } = await agent.Account.login(
      creds
    );
    store.commonStore.setToken(result.token);
  };

  register = async (registerData: RegisterUserDto) => {
    try {
      const result: { userId: number; token: string } =
        await agent.Account.register(registerData);
      store.commonStore.setToken(result.token);
    } catch (err) {
      console.log("Error while registering user: ", err);
    }
  };

  forgotPassword = async (email: string) => {
    try {
      await agent.Account.forgotPassword(email);
    } catch (err) {
      console.log("Error while trying to reset forgotten password: ", err);
    }
  };
  resetPassword = async (payload: any) => {
    try {
      const result = await agent.Account.resetPassword(payload);
      store.commonStore.setToken(result.token);
    } catch (err) {
      console.log("Error while trying to reset password: ", err);
    }
  };
  verifyEmail = async (payload: any) => {
    try {
      const result = await agent.Account.verifyEmail(payload);
      store.commonStore.setToken(result.token);
    } catch (err) {
      console.log("Error while trying to verify email: ", err);
    }
  };

  getUserInfo = async () => {
    try {
      const user = await agent.User.getUserInfo();
      runInAction(() => (this.user = user));
    } catch (error) {
      console.log("Error while fetching user info: ", error);
    }
  };

  updateUserName = async (firstName: string, lastName: string) => {
    try {
      await agent.User.updateName(firstName, lastName);
    } catch (error) {
      console.log("Error while updating user info: ", error);
    }
  };

  updateUserPassword = async (
    oldPassword: string,
    newPassword: string,
    repeatedNew: string
  ) => {
    try {
      await agent.User.updatePassword(oldPassword, newPassword, repeatedNew);
    } catch (error) {
      console.log("Error while updating user password: ", error);
      throw error;
    }
  };

  // forgotPassword = async (email: string) => {
  //     try {
  //         await agent.Account.forgotPassword({ email: email });
  //         return true;
  //     } catch (error) {
  //         console.log("Error while sending forgot password email: ", error);
  //         return false;
  //     }
  // };

  // setNewPassword = async (data: SetAdminPassword) => {
  //     try {
  //         await agent.Account.setAdminPassword(data);
  //         return true;
  //     } catch (error) {
  //         console.log("Error while setting new password: ", error);
  //         return false;
  //     }
  // };

  // getAdminInfo = async () => {
  //     try {
  //         const user = await agent.Admin.userInfo();
  //         runInAction(() => (this.user = user));
  //     } catch (error) { }
  // };

  setSplashScreen = (value: boolean) => {
    this.showSplashScreen = value;
  };

  logout = () => {
    store.commonStore.setToDefault();
    this.user = null;
    this.setSplashScreen(false);
    return true;
  };
}
