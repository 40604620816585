import agent from "../api/agent";
import { ChangeStateOfDemandShiftingDto } from "../models/demandShifting";

export default class DemandShiftingStore {

    changeStateOfDemandShifting = async (data: ChangeStateOfDemandShiftingDto) => {
        try {
            await agent.DemandShifting.changeState(data);
        } catch (err) {
            console.log("Error while changing state of demand shifting", err);
        }
    }
}