import { useEffect, useState } from "react";
import Session from "../../components/Session/Session";
import StatusBar from "../../components/StatusBar/StatusBar";
import EditActive from "../../components/Session/EditActive";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { setEmitFlags } from "typescript";
import { useNavigate } from "react-router-dom";
import { User } from "../../app/models/user";
import { HomeSessionDto } from "../../app/models/session";
import { runInAction } from "mobx";

interface Props {
  user: User | null;
  handleStopCharging?: (sessionId: number) => void;
  handleEdit: (sessionId: number) => void;
  handleToggleAttempt?: (id: number, newState: boolean) => void;
  handlePageChange?: (pageName: string) => void;
}

const Home = observer(
  ({
    handleToggleAttempt,
    handleStopCharging,
    handleEdit,
    user,
    handlePageChange,
  }: Props) => {
    const { sessionStore, signalRStore } = useStore();
    const [sessionsToShow, setSessionsToShow] = useState(3);
    var clockTicking: NodeJS.Timer | undefined = undefined;

    useEffect(() => {
      const getSessions = async () => {
        await sessionStore.getHomeSessions();
      };
      getSessions();
      startClock();
      // sessionStore.homeSessions?.find((el) => el.sessionStatus === "Active") &&
      connectOnSignalR();
      return () => {
        clearInterval(clockTicking);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const startClock = () => {
      clockTicking = setInterval(() => {
        sessionStore.durationClockTick();
      }, 1000);
    };

    const connectOnSignalR = async () => {
      await signalRStore.startConnection("/hubs/sessions");
      sessionStore.homeSessions?.forEach(
        async (session) =>
          await signalRStore.subscribeOnRemoteAction(
            "SubscribeB2C",
            session.id,
            (err: any) => console.error("Subscribe B2C error: ", err)
          )
      );
      signalRStore.listenRemoteAction(
        "updateSession",
        (updateSessionSignalRDto: any) => {
          const { sessionId, currentSoC, energyConsumed, currentPower } =
            updateSessionSignalRDto;
          console.log("Session update received:", updateSessionSignalRDto);
          const newSessions: HomeSessionDto[] | undefined =
            sessionStore.homeSessions?.map((session) =>
              session.id === sessionId
                ? {
                  ...session,
                  carBattery:
                    currentSoC === -1
                      ? "n/a"
                      : `${String(Math.round(currentSoC))}%`,
                  electricityConsumed:
                    `${energyConsumed.toFixed(1)} kWh` || "0 kWh",
                  chargerPower: `${currentPower.toFixed(1)} kW` || "0 kW",
                }
                : session
            );
          runInAction(() => (sessionStore.homeSessions = newSessions));
        }
      );
    };

    const activeSessions = sessionStore.homeSessions?.filter(
      (session) => session.sessionStatus === "Active"
    );

    return (
      <>
        <p className="font-bold text-dark-green mb-5">
          Hi {user?.firstName} {user?.lastName}!
        </p>
        <StatusBar />

        {sessionStore.homeSessions?.length === 0 ? (
          <div className="absolute px-6 w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-[20px] font-bold text-dark-green">
            Please connect your charger with the car to start charging session.
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-3 overflow-y-auto">
              {activeSessions?.length === 0 && (
                <div className="mb-3 text-[20px] font-bold text-dark-green">
                  Please connect your charger with the car to start charging
                  session.
                </div>
              )}
              {sessionStore.homeSessions
                ?.slice(0, sessionsToShow)
                .map((session, index) => (
                  <div key={session.id}>
                    <Session
                      handleEdit={handleEdit}
                      session={session}
                      handleStopCharging={handleStopCharging}
                      handleToggleAttempt={handleToggleAttempt}
                    />
                    {index + 1 === sessionsToShow && (
                      <div
                        onClick={() =>
                          handlePageChange && handlePageChange("history")
                        }
                        className="flex flex-col items-center text-[12px] font-semibold text-black justify-center text-center mt-3 mb-3 cursor-pointer"
                      >
                        See more sessions
                        <img
                          width={25}
                          height={25}
                          src="/assets/svg/arrow/arrowDown.svg"
                          alt="See more sessions"
                        />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}
      </>
    );
  }
);
export default Home;
