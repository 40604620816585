import axios, { AxiosResponse } from "axios";
import { store } from "../stores/store";
import { LoginUserDto, RegisterUserDto } from "../models/user";
import { ChangeStateOfDemandShiftingDto } from "../models/demandShifting";
import { UpdateActiveSessionDto } from "../models/session";
const baseURL = process.env.REACT_APP_BASE_URL + "/api/v1/B2CWeb";

const responseBody = (response: AxiosResponse) => response.data;

axios.interceptors.request.use((config) => {
  const token = store.commonStore.token;
  if (token && config.headers) config.headers.Authorization = `bearer ${token}`;
  return config;
});

const requests = {
  get: <T>(url: string) => axios.get<T>(`${baseURL}${url}`).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axios.post<T>(`${baseURL}${url}`, body).then(responseBody),
  put: <T>(url: string, body: {}) =>
    axios.put<T>(`${baseURL}${url}`, body).then(responseBody),
  delete: <T>(url: string) =>
    axios.delete<T>(`${baseURL}${url}`).then(responseBody),
};

const Account = {
  login: (user: LoginUserDto) => requests.post("/Auth/LoginUser", user),
  register: (registerData: RegisterUserDto) =>
    requests.post("/Auth/RegisterUser", registerData),
  forgotPassword: (email: string) =>
    requests.post("/Auth/ForgotPassword", { email: email }),
  resetPassword: (payload: any) =>
    requests.post("/Auth/ResetPassword", payload),
  verifyEmail: (payload: any) =>
    requests.post("/Auth/VerifyUserEmail", payload),
};

const User = {
  getUserInfo: () => requests.get("/Users/GetUserInfo"),
  updateName: (firstName: string, lastName: string) =>
    requests.post("/Users/UpdateUsersName", {
      firstName: firstName,
      lastName: lastName,
    }),
  updatePassword: (
    oldPassword: string,
    newPassword: string,
    newPasswordRepeated: string
  ) =>
    requests.post("/Users/ChangePassword", {
      oldPassword: oldPassword,
      newPassword: newPassword,
      newPasswordRepeated: newPasswordRepeated,
    }),
};

const Session = {
  homeSessions: () => requests.get("/Sessions/GetSessionData"),
  stopSession: (id: number) =>
    requests.post(`/Sessions/StopSessionById?sessionId=${id}`, {}),
  updateActiveSession: (data: UpdateActiveSessionDto) =>
    requests.post("/Sessions/UpdateActiveSession", data),
  historySessions: () => requests.get("/Sessions/GetHistorySessions"),
};

const Charger = {
  chargersStatusCount: () => requests.get("/Chargers/GetChargersStatusCount"),
  chargers: () => requests.get("/Chargers/GetChargers"),
  deleteCharger: (id: number) =>
    requests.delete(`/Chargers/DeleteCharger?id=${id}`),
  addCharger: (id: string) =>
    requests.post(`/Chargers/AddCharger?chargerId=${id}`, {}),
};

const DemandShifting = {
  changeState: (data: ChangeStateOfDemandShiftingDto) =>
    requests.post("/DemandShifting/ChangeStateOfDemandShifting", data),
};

const agent = {
  Account,
  User,
  Charger,
  Session,
  DemandShifting,
};

export default agent;
