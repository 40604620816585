import { useState } from "react";
import Toggle from "../Toggle/Toggle";
import SessionStatus from "./SessionStatus";
import Modal from "../Modal/Modal";
import { HomeSessionDto } from "../../app/models/session";

interface Props {
  session: HomeSessionDto;
  handleStopCharging?: (sessionId: number) => void;
  handleEdit: (sessionId: number) => void;
  handleToggleAttempt?: (id: number, newState: boolean) => void;
}

const Session = ({
  session,
  handleToggleAttempt,
  handleEdit,
  handleStopCharging,
}: Props) => {
  return (
    <div className="flex relative flex-col justify-between border-2 rounded-[5px] border-[#E6E6E6] py-3 px-4">
      <div className="flex py-2.5 border-b-2 border-[#F7F7F7] items-center justify-between text-[12px] font-semibold text-dark-green">
        Demand shifting
        <div className="flex gap-2">
          <Toggle session={session} handleToggleAttempt={handleToggleAttempt} />
          {session.sessionStatus === "Active" && (
            <div onClick={() => handleEdit(session.id)}>
              <img src="/assets/svg/edit/edit.svg" alt="edit" />
            </div>
          )}
        </div>
      </div>
      <div className="flex items-end py-2.5 border-b-2 border-[#F7F7F7] justify-between">
        <div>
          <p className="text-[20px] font-semibold text-dark-green">
            {session.chargerName}
          </p>
          <p className="text-[12px] font-semibold text-dark-green">
            Session Status
          </p>
        </div>
        <SessionStatus status={session.sessionStatus} />
      </div>

      <div className="flex justify-between border-b-2 border-[#F7F7F7] py-2.5">
        <div className="flex flex-col gap-2">
          <p className="text-[10px] font-semibold text-darkgreen">
            Session Start
          </p>
          <p className="text-[12px] font-semibold text-darkgreen">
            {session.timeStart}
          </p>
        </div>
        {session.sessionStatus !== "Active" && (
          <div className="flex flex-col gap-2">
            <p className="text-[10px] font-semibold text-darkgreen">
              Session Stop
            </p>
            <p className="text-[12px] font-semibold text-darkgreen">
              {session.timeStop}
            </p>
          </div>
        )}
      </div>
      <div className="border-b-2 border-[#F7F7F7] py-2.5">
        <div className="flex justify-between">
          <p className="text-[12px] font-semibold text-darkgreen">
            Connector ID
          </p>
          <p className="text-[12px] font-semibold text-darkgreen">
            {session.connectorId}
          </p>
        </div>
      </div>
      <div className="border-b-2 border-[#F7F7F7] py-2.5">
        <div className="flex justify-between">
          <p className="text-[12px] font-semibold text-darkgreen">
            Session Duration
          </p>
          <p className="text-[12px] font-semibold text-darkgreen">
            {session.duration}
          </p>
        </div>
      </div>
      <div className="border-b-2 border-[#F7F7F7] py-2.5">
        <div className="flex justify-between">
          <p className="text-[12px] font-semibold text-darkgreen">
            Energy Charged
          </p>
          <p className="text-[12px] font-semibold text-darkgreen">
            {session.electricityConsumed}
          </p>
        </div>
      </div>
      {session.sessionStatus === "Active" && (
        <>
          <div className="border-b-2 border-[#F7F7F7] py-2.5">
            <div className="flex justify-between">
              <p className="text-[12px] font-semibold text-darkgreen">
                Instant Power
              </p>
              <p className="text-[12px] font-semibold text-darkgreen">
                {session.chargerPower}
              </p>
            </div>
          </div>
          <div className="border-b-2 border-[#F7F7F7] py-2.5">
            <div className="flex justify-between">
              <p className="text-[12px] font-semibold text-darkgreen">
                Car Battery
              </p>
              <p className="text-[12px] font-semibold text-darkgreen">
                {session.carBattery}
              </p>
            </div>
          </div>
        </>
      )}

      {session.sessionStatus === "Active" && (
        <button
          onClick={() => handleStopCharging && handleStopCharging(session.id)}
          className="w-full mt-5 mb-1 text-[12px] h-9 rounded-[5px] font-semibold text-white bg-[#DA3333]"
        >
          Stop session
        </button>
      )}
    </div>
  );
};

export default Session;
