import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import MySpinner from "../../components/Spinner/MySpinner";

const SetNewPassword = () => {
  const { code, userId } = useParams();
  const { userStore, commonStore } = useStore();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [visiblePassword, setVisiblePassword] = useState(false);
  const [touched, setTouched] = useState({
    password: false,
    confirmPassword: false,
  });
  const [errors, setErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (touched.password) {
      if (password.length < 6) {
        setErrors((prev) => ({
          ...prev,
          password: "Password must be at least 6 characters long",
        }));
      } else {
        setErrors((prev) => ({ ...prev, password: "" }));
      }
    }

    if (touched.confirmPassword) {
      if (confirmPassword !== password) {
        setErrors((prev) => ({
          ...prev,
          confirmPassword: "Passwords do not match",
        }));
      } else {
        setErrors((prev) => ({ ...prev, confirmPassword: "" }));
      }
    }
  }, [password, confirmPassword, touched.password, touched.confirmPassword]);

  const handleBlur = (field: string) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
  };

  const isFormValid =
    password.length >= 6 &&
    confirmPassword === password &&
    confirmPassword.length > 0;

  const handleConfirm = () => {
    setLoading(true);

    if (isFormValid) {
      userStore
        .resetPassword({
          id: Number(userId),
          activationCode: code,
          newPassword: password,
          newPasswordRepeated: confirmPassword,
        })
        .then(() => {
          navigate("/dashboard");
          setLoading(false);
        });
    }
  };

  return (
    <div className="relative flex flex-col justify-center bg-white px-8 pb-8 pt-[100px] text-white h-screen ">
      <img
        className="absolute top-10 left-7"
        src="/assets/svg/arrow/arrowBack.svg"
        alt="back"
        onClick={() => navigate(-1)}
      />
      <div className="flex flex-col gap-6 w-full">
        <p className="text-[18px] font-semibold text-dark-green mb-4">
          Set new password
        </p>

        <div className="flex flex-col gap-2">
          <div className="relative">
            <input
              className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
              type={visiblePassword ? "text" : "password"}
              placeholder="Password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onBlur={() => handleBlur("password")}
            />
            <img
              src={
                visiblePassword
                  ? "/assets/svg/eye/eye-open.svg"
                  : "/assets/svg/eye/eye-closed.svg"
              }
              alt="Toggle visibility"
              className="absolute cursor-pointer top-1 right-[10px]"
              onClick={() => setVisiblePassword(!visiblePassword)}
            />
          </div>
          {touched.password && errors.password && (
            <p className="text-error-red text-[10px]">{errors.password}</p>
          )}
        </div>

        <div className="flex flex-col gap-2">
          <div className="relative">
            <input
              className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
              type={visiblePassword ? "text" : "password"}
              placeholder="Confirm Password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              onBlur={() => handleBlur("confirmPassword")}
            />
            <img
              src={
                visiblePassword
                  ? "/assets/svg/eye/eye-open.svg"
                  : "/assets/svg/eye/eye-closed.svg"
              }
              alt="Toggle visibility"
              className="absolute cursor-pointer top-1 right-[10px]"
              onClick={() => setVisiblePassword(!visiblePassword)}
            />
          </div>
          {touched.confirmPassword && errors.confirmPassword && (
            <p className="text-error-red text-[10px]">
              {errors.confirmPassword}
            </p>
          )}
        </div>
      </div>

      <button
        onClick={handleConfirm}
        className={`w-full mt-10 h-12 rounded-[10px] text-white text-[12px] font-semibold ${isFormValid
            ? "bg-light-green"
            : "bg-light-green opacity-50 cursor-not-allowed"
          }`}
        disabled={!isFormValid}
      >
        Reset Password
      </button>
      {loading && <MySpinner />}
    </div>
  );
};

export default SetNewPassword;
