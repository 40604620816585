import { useState } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import SessionHistoryElement from "../../components/Session/SessionHistoryElement";

const SessionHistory = observer(() => {
  const { sessionStore } = useStore();
  const { historySessions } = sessionStore;
  const [sessionsToShow, setSessionsToShow] = useState(3);

  const handleTouchEnd = () => { };

  const handleSeeMore = () => {
    setSessionsToShow((prevCount) =>
      Math.min(prevCount + 3, (historySessions ? historySessions.length : 3))
    );
  };

  return (
    <>
      <div className="text-center">
        <p className="text-[20px] font-bold text-dark-green mt-2 mb-5">
          Session history
        </p>
      </div>
      <div className="flex flex-col gap-3 overflow-y-auto">
        {historySessions &&
          historySessions.slice(0, sessionsToShow).map((session) => (
            <div onTouchEnd={handleTouchEnd} key={session.id}>
              <SessionHistoryElement session={session} />
            </div>
          ))}

        {sessionsToShow < (historySessions ? historySessions.length : 3) && (
          <div
            onClick={handleSeeMore}
            className="flex flex-col items-center text-[12px] font-semibold text-black justify-center text-center mt-2 mb-1 cursor-pointer"
          >
            See more sessions
            <img
              width={25}
              height={25}
              src="/assets/svg/arrow/arrowDown.svg"
              alt="See more sessions"
            />
          </div>
        )}
      </div>
    </>
  );
});

export default SessionHistory;
