import { useEffect, useState } from "react";
import Charger from "../../components/Charger/Charger";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";

interface Props {
  handleDeleteAttempt: (chargerId: number) => void;
}

const ChargersList = observer(({ handleDeleteAttempt }: Props) => {
  const { chargerStore } = useStore();
  const [swipedIndex, setSwipedIndex] = useState<number | null>(null);
  const [touchStartX, setTouchStartX] = useState(0);

  useEffect(() => {
    chargerStore.getChargers();
  }, [chargerStore]);

  const handleTouchStart = (e: React.TouchEvent, index: number) => {
    setSwipedIndex(null);
    const touch = e.touches[0];
    setTouchStartX(touch.clientX);
  };

  const handleTouchMove = (e: React.TouchEvent, index: number) => {
    const touch = e.touches[0];
    const swipeDistance = touchStartX - touch.clientX;

    if (swipeDistance > 50) {
      setSwipedIndex(index);
    }
  };

  const handleTouchEnd = () => {};
  return (
    <>
      <div className="text-center">
        <p className="text-[20px] font-bold text-dark-green mt-2 mb-5">
          Chargers list
        </p>
      </div>
      <div className="flex flex-col gap-3 overflow-y-auto">
        {chargerStore.chargers?.map((charger, index) => (
          <div
            onTouchStart={(e) => handleTouchStart(e, index)}
            onTouchMove={(e) => handleTouchMove(e, index)}
            onTouchEnd={handleTouchEnd}
            key={index}
            className={`relative transform transition-transform duration-300 ${
              swipedIndex === index ? "-translate-x-12" : ""
            }`}
          >
            <Charger key={index} charger={charger} />
            <img
              onClick={() => handleDeleteAttempt(charger.id)}
              onTouchStart={(e) => e.stopPropagation()}
              className={`absolute flex items-center justify-center bg-white transition-opacity duration-300 ${
                swipedIndex === index
                  ? "opacity-100 right-[-45px] top-[90px]"
                  : "opacity-0"
              }`}
              src="/assets/svg/delete/bin.svg"
              alt=""
            />
          </div>
        ))}
      </div>
    </>
  );
});
export default ChargersList;
