import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Popup from "../../components/Popup/Popup";

const ResetPassword = () => {
  const { userStore } = useStore();
  const [showPopup, setShowPopup] = useState(false);
  const [emailValidator, setEmailValidator] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleNext = () => {
    if (email) {
      userStore.forgotPassword(email);
      setShowPopup(true);
    }
  };

  const validateEmail = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    validateEmail(email) ? setEmailValidator(true) : setEmailValidator(false);
  }, [email]);
  return (
    <div className="relative flex items-center justify-center bg-white px-10 text-white h-screen ">
      <img
        className="absolute top-10 left-9"
        src="/assets/svg/arrow/arrowBack.svg"
        alt="back"
        onClick={() => navigate(-1)}
      />
      <div className="flex flex-col gap-7 w-full">
        <p className="text-[18px] font-semibold text-dark-green">
          Password Reset
        </p>
        <div className="relative">
          <input
            className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
            type="text"
            placeholder="Email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {emailValidator && (
            <img
              src="/assets/svg/tick/green-tick.svg"
              alt=""
              className="absolute cursor-pointer top-0 right-[10px]"
            />
          )}
        </div>
        <button
          onClick={handleNext}
          className={`w-full h-12 rounded-[10px] bg-light-green text-white text-[12px] font-semibold ${
            !emailValidator ? "opacity-50" : ""
          } `}
          disabled={!emailValidator}
        >
          Next
        </button>
      </div>
      {showPopup && (
        <Popup forgot email={email} handleClose={() => setShowPopup(false)} />
      )}
    </div>
  );
};
export default ResetPassword;
