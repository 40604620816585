const SplashScreen = () => {
  return (
    <div className="relative h-screen custom-gradient flex flex-col justify-center items-center">
      <p className="audiowide-regular text-[42px] text-[#E6F6F4]">EnergAI</p>
      <div className="absolute bottom-12 rounded-[100px] w-[30%] h-[4px] bg-white"></div>
    </div>
  );
};

export default SplashScreen;
