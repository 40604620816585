import { HomeSessionDto } from "../../app/models/session";

interface Props {
  session: HomeSessionDto;
  handleToggleAttempt?: (id: number, newState: boolean) => void;
}

const Toggle = ({ handleToggleAttempt, session }: Props) => {
  return (
    <label
      className="relative inline-flex items-center cursor-pointer"
      onClick={(e) => {
        e.stopPropagation();
        session.sessionStatus === "Active" && handleToggleAttempt?.(session.id, !session.demandShifting);
      }}
    >
      <input
        type="checkbox"
        className="sr-only peer"
        disabled={true}
        checked={session.demandShifting}
      />
      <div className="w-[55px] h-[26px] bg-[#E6E6E6] peer-focus:outline-none rounded-full peer peer-checked:bg-[#80C042] after:content-[''] after:absolute after:top-[2px] after:left-[3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[21px] after:w-[21px] after:transition-all after:duration-500 peer-checked:after:translate-x-[28px]"></div>
    </label>
  );
};
export default Toggle;
