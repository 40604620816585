import "react-calendar/dist/Calendar.css";
import "./DateSelect.css";
import Calendar from "react-calendar";
import { Value } from "react-calendar/dist/cjs/shared/types";
import React from "react";

interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
}

interface Props {
  start: string | null;
  end: string | null;
  setSelected: (value: any) => void;
  demandShift?: boolean;
}

const DateSelect = ({ start, end, setSelected, demandShift }: Props) => {
  const today = new Date();

  const initialDate = demandShift ? today : start ? new Date(start) : today;

  const selectionRange = {
    startDate: initialDate,
    endDate: end ? new Date(end) : today,
  };

  const handleDateChange = (value: Value) => {
    const selected = value as Date;

    setSelected(selected);
  };

  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    const { startDate } = selectionRange;

    const today = new Date().setHours(0, 0, 0, 0);
    const currentTime = new Date(date).setHours(0, 0, 0, 0);
    const selectedTime = startDate
      ? new Date(startDate).setHours(0, 0, 0, 0)
      : null;

    if (view === "month" && selectedTime && currentTime === selectedTime) {
      return "react-calendar__tile--activee";
    }
    if (currentTime === today) {
      return "react-calendar__tile--today";
    }

    return null;
  };

  return (
    <Calendar
      locale="en-UK"
      className="calendar"
      tileClassName={tileClassName}
      next2Label={null}
      prev2Label={null}
      selectRange={false}
      defaultValue={today}
      showNeighboringMonth={false}
      onChange={handleDateChange}
      value={start}
    />
  );
};

export default DateSelect;
