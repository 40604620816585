import { useState, useEffect } from "react";
import SplashScreen from "../../components/SplashScreen/SplashScreen";
import { useNavigate } from "react-router-dom";
import { LoginUserDto } from "../../app/models/user";
import MySpinner from "../../components/Spinner/MySpinner";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";

const Login = observer(() => {
  const navigate = useNavigate();
  const { userStore, commonStore } = useStore();
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    emailEmpty: false,
    passwordEmpty: false,
    incorrectEmailPassword: false,
  });

  const [credentials, setCredentials] = useState<LoginUserDto>({
    email: "",
    password: "",
    rememberMe: false,
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      userStore.setSplashScreen(false);
    }, 2600);

    return () => clearTimeout(timer);
  }, [userStore, userStore.showSplashScreen]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCredentials((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      setErrors({
        emailEmpty: false,
        passwordEmpty: false,
        incorrectEmailPassword: false,
      });

      if (!credentials.email || !credentials.password) {
        setErrors((prev) => ({
          ...prev,
          emailEmpty: !Boolean(credentials.email),
          passwordEmpty: !Boolean(credentials.password),
        }));
        return;
      }
      await userStore.login(credentials);
      if (commonStore.isLoggedIn()) navigate("/dashboard");
    } catch (error) {
      console.log("Login failed", error);
      setErrors((prev) => ({
        ...prev,
        incorrectEmailPassword: true,
      }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {userStore.showSplashScreen ? (
        <SplashScreen />
      ) : (
        <div className="flex flex-col gap-7 items-center justify-center bg-white px-8 text-white h-screen ">
          <div className="flex flex-col w-full">
            <p className="text-[18px] font-semibold text-dark-green">
              Login to your account
            </p>
            <p className="text-[12px] text-light-gray">
              Please enter your credentials to proceed
            </p>
          </div>
          <div className="flex flex-col w-full gap-1">
            <div>
              <input
                className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
                type="text"
                placeholder="Email"
                name="email"
                value={credentials.email}
                onChange={handleChange}
              />
            </div>
            {errors.emailEmpty ? (
              <p className="text-error-red text-[10px] mb-3">
                Email is required
              </p>
            ) : errors.incorrectEmailPassword ? (
              <p className="text-error-red text-[10px]">
                Incorrect email or password
              </p>
            ) : (
              <div className="mb-3" />
            )}
            <div className="relative">
              <input
                className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] placeholder:text-[12px] border-solid border-[#F7F7F7]"
                type={visiblePassword ? "text" : "password"}
                placeholder="Password"
                name="password"
                value={credentials.password}
                onChange={handleChange}
              />
              <img
                src={
                  visiblePassword
                    ? "/assets/svg/eye/eye-open.svg"
                    : "/assets/svg/eye/eye-closed.svg"
                }
                alt=""
                className="absolute cursor-pointer top-1 right-[10px]"
                onClick={() => setVisiblePassword(!visiblePassword)}
              />
            </div>
            {errors.passwordEmpty && (
              <p className="text-error-red text-[10px]">Password is required</p>
            )}
            {errors.incorrectEmailPassword && (
              <p className="text-error-red text-[10px] mb-2">
                Incorrect email or password
              </p>
            )}
          </div>
          <div className="flex flex-col items-center gap-4 w-full mt-3">
            <button
              className="w-full h-12 rounded-[10px] bg-light-green text-white text-[12px] font-semibold"
              onClick={handleLogin}
            >
              Login
            </button>
            <div
              onClick={() => navigate("/forgot-password")}
              className="text-[12px] mb-2 font-medium text-dark-green"
            >
              Forgot Password?
            </div>
            <div className="w-full flex gap-3 items-baseline text-light-gray text-[12px] font-medium">
              <div className="w-full h-[2px] bg-[#F7F7F7]"></div>
              or
              <div className="w-full h-[2px] bg-[#F7F7F7]"></div>
            </div>
            <button
              onClick={() => navigate("/create-account")}
              className="w-full mt-3 h-12 rounded-[10px] bg-dark-green text-white text-[12px] font-semibold"
            >
              Create an Account
            </button>
          </div>
        </div>
      )}
      {loading && <MySpinner />}
    </>
  );
});

export default Login;
