import { HistorySession } from "../../app/models/session";

interface Props {
  session: HistorySession;
}

const SessionHistoryElement = ({ session }: Props) => {
  return (
    <div className="flex relative flex-col justify-between border-2 rounded-[5px] border-[#E6E6E6] py-1 px-3">
      <div className="flex justify-between border-b-2 border-[#F7F7F7] py-2">
        <p className="text-[12px] font-semibold text-darkgreen">Start Time</p>
        <p className="text-[12px] font-semibold text-darkgreen">
          {session.timeStart}
        </p>
      </div>
      <div className="flex justify-between border-b-2 border-[#F7F7F7] py-2">
        <p className="text-[12px] font-semibold text-darkgreen">Stop Time</p>
        <p className="text-[12px] font-semibold text-darkgreen">
          {session.timeStop}
        </p>
      </div>
      <div className="flex justify-between border-b-2 border-[#F7F7F7] py-2">
        <p className="text-[12px] font-semibold text-darkgreen">Duration</p>
        <p className="text-[12px] font-semibold text-darkgreen">
          {session.duration}
        </p>
      </div>
      <div className="flex justify-between items-center border-b-2 border-[#F7F7F7] py-2">
        <p className="text-[12px] font-semibold text-darkgreen">
          Demand shifting
        </p>
        {session.demandShifting ? (
          <div className="flex items-center justify-center w-[54px] border-[1.5px] border-[#80C042] bg-[#F2F9EC] text-[#80C042] rounded-[5px] py-2 px-3 inline-block text-[12px] font-semibold">
            ON
          </div>
        ) : (
          <div className="w-[54px] bg-[#FBEBEB] border-[1.5px] border-[#DA3333] text-[#DA3333] rounded-[5px] py-2 px-3 inline-block text-[12px] font-semibold">
            OFF
          </div>
        )}
      </div>
      <div className="flex justify-between border-b-2 border-[#F7F7F7] py-2">
        <p className="text-[12px] font-semibold text-darkgreen">
          Charging session ID
        </p>
        <p className="text-[12px] font-semibold text-darkgreen">{session.id}</p>
      </div>
      <div className="flex justify-between border-b-2 border-[#F7F7F7] py-2">
        <p className="text-[12px] font-semibold text-darkgreen">
          Electricity consumed
        </p>
        <p className="text-[12px] font-semibold text-darkgreen">
          {session.electricityConsumed}
        </p>
      </div>
      <div className="flex justify-between py-2">
        <p className="text-[12px] font-semibold text-darkgreen">Connector ID</p>
        <p className="text-[12px] font-semibold text-darkgreen">
          {session.connectorId}
        </p>
      </div>
    </div>
  );
};

export default SessionHistoryElement;
