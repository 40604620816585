import { useEffect, useState } from "react";
import NavBar from "../../components/NavBar/NavBar";
import ChargersList from "../../pages/ChargersList/ChargersList";
import Home from "../../pages/Home/Home";
import Profile from "../../pages/Profile/Profile";
import SessionHistory from "../../pages/SessionHistory/SessionHistory";
import Modal from "../../components/Modal/Modal";
import EditActive from "../../components/Session/EditActive";
import { useStore } from "../stores/store";
import MySpinner from "../../components/Spinner/MySpinner";
import { observer } from "mobx-react-lite";
import AddCharger from "../../components/Charger/AddCharger";
import Toast from "../../components/Toast/Toast";
import ProfileModal from "../../components/ProfileModal/UpdateNameModal";
import UpdateNameModal from "../../components/ProfileModal/UpdateNameModal";
import UpdatePasswordModal from "../../components/ProfileModal/UpdatePasswordModal";

const Layout = observer(() => {
  const {
    demandShiftingStore,
    sessionStore,
    userStore,
    commonStore,
    chargerStore,
    signalRStore,
  } = useStore();
  const [activeTab, setActiveTab] = useState(
    sessionStorage.getItem("activeTab") || "home"
  );

  const [showStopChargingModal, setShowStopChargingModal] = useState(false);
  const [deleteCharger, setDeleteCharger] = useState(false);
  const [addCharger, setAddCharger] = useState(false);
  const [showAddedChargerToast, setShowAddedChargerToast] = useState(false);
  const [showAddedChargerToastFail1, setShowAddedChargerToastFail1] =
    useState(false);
  const [showStoppedChargingToast, setShowStoppedChargingToast] =
    useState(false);
  const [editFirstName, setEditFirstName] = useState(false);
  const [editLastName, setEditLastName] = useState(false);
  const [editPassword, setEditPassword] = useState(false);

  const [toggleModal, setToggleModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedSession, setSelectedSession] = useState<number | null>(null);
  const [selectedCharger, setSelectedCharger] = useState<number | null>(null);
  const [pendingToggleRow, setPendingToggleRow] = useState<{
    id: number;
    newState: boolean;
  } | null>(null);

  useEffect(() => {
    const getData = async () => {
      await userStore.getUserInfo();
      await sessionStore.getHistoySessions();
    };
    commonStore.isLoggedIn() && getData();
  }, [commonStore, sessionStore, userStore]);

  useEffect(() => sessionStorage.setItem("activeTab", activeTab), [activeTab]);

  const handleToggleAttempt = (id: number, newState: boolean) => {
    setPendingToggleRow({
      id: id,
      newState: newState,
    });
    setToggleModal(true);
  };

  const handleEditAttempt = (sessionId: number) => {
    setSelectedSession(sessionId);
    setEditModal(true);
  };

  const handleStopChargingAttempt = (sessionId: number) => {
    setSelectedSession(sessionId);
    setShowStopChargingModal(true);
  };

  const handleDeleteChargerAttempt = (chargerId: number) => {
    setSelectedCharger(chargerId);
    setDeleteCharger(true);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "home":
        return (
          <Home
            user={userStore.user}
            handleEdit={handleEditAttempt}
            handleToggleAttempt={handleToggleAttempt}
            handleStopCharging={handleStopChargingAttempt}
            handlePageChange={setActiveTab}
          />
        );
      case "chargers":
        return (
          <ChargersList handleDeleteAttempt={handleDeleteChargerAttempt} />
        );
      case "history":
        return <SessionHistory />;
      case "profile":
        return (
          <Profile
            handleEditFirstName={() => setEditFirstName(true)}
            handleEditLastName={() => setEditLastName(true)}
            handleEditPassword={() => setEditPassword(true)}
          />
        );
      default:
        return (
          <Home
            user={userStore.user}
            handleEdit={handleEditAttempt}
            handleToggleAttempt={handleToggleAttempt}
            handleStopCharging={handleStopChargingAttempt}
          />
        );
    }
  };

  const handleConfirmEdit = async (
    id: number,
    batteryCapacity: number,
    currentSoC: number,
    stopDatetime: string
  ) => {
    setLoading(true);
    await sessionStore.updateActiveSession({
      sessionId: id,
      batteryCapacity: batteryCapacity,
      initialBatteryState: currentSoC,
      preferredEndTime: stopDatetime,
    });
    setLoading(false);
    setEditModal(false);
    setSelectedSession(null);
  };

  const handleStopCharging = async () => {
    if (selectedSession) {
      setLoading(true);
      await sessionStore
        .stopSession(selectedSession)
        .then(() => signalRStore.closeConnection());
      setTimeout(async () => {
        await sessionStore.getHomeSessions();
        await chargerStore.getChargersStatusCount();
        setLoading(false);
        setShowStopChargingModal(false);
        setShowStoppedChargingToast(true);
        setSelectedSession(null);
      }, 1500);
    }
  };

  const handleDeleteCharger = async () => {
    if (selectedCharger) {
      setLoading(true);
      await chargerStore.deleteCharger(selectedCharger);
      await chargerStore.getChargers();
      setLoading(false);
      setSelectedCharger(null);
    }
    setDeleteCharger(false);
  };

  const handleToggleDemandShifting = async () => {
    if (pendingToggleRow) {
      setLoading(true);
      await demandShiftingStore.changeStateOfDemandShifting({
        sessionId: pendingToggleRow.id,
        isEnabled: pendingToggleRow.newState,
      });
      await sessionStore.getHomeSessions();
      setLoading(false);
      setPendingToggleRow(null);
    }
    setToggleModal(false);
  };

  const handleConfirmAddCharger = async (chargerId: string, pin: number) => {
    setLoading(true);
    const res = await chargerStore.addCharger(chargerId);
    setTimeout(async () => {
      res && (await chargerStore.getChargers());
      setLoading(false);
      setAddCharger(false);
      res
        ? setShowAddedChargerToast(true)
        : setShowAddedChargerToastFail1(true);
    }, 1500);
  };

  return (
    <div style={{ overscrollBehavior: "none" }} className="relative">
      <div className="flex flex-col h-screen px-6 pt-7 pb-[90px]">
        {renderContent()}
      </div>
      <NavBar
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        setAddCharger={() => setAddCharger(true)}
      />{" "}
      {showStopChargingModal && (
        <Modal
          handleConfirm={handleStopCharging}
          close={setShowStopChargingModal}
          text="Are you sure you want to stop charging session?"
        />
      )}
      {toggleModal && (
        <Modal
          handleConfirm={handleToggleDemandShifting}
          close={setToggleModal}
          text={`Are you sure you want to proceed with with turning demand shifting ${
            pendingToggleRow?.newState ? "on" : "off"
          }?`}
        />
      )}
      {editModal && (
        <EditActive
          sessionId={selectedSession!}
          close={setEditModal}
          handleConfirm={handleConfirmEdit}
        />
      )}
      {editFirstName && (
        <UpdateNameModal
          firstName={true}
          close={() => setEditFirstName(false)}
        />
      )}
      {editPassword && (
        <UpdatePasswordModal close={() => setEditPassword(false)} />
      )}
      {editLastName && (
        <UpdateNameModal
          firstName={false}
          close={() => setEditLastName(false)}
        />
      )}
      {loading && <MySpinner />}
      {deleteCharger && (
        <Modal
          handleConfirm={handleDeleteCharger}
          close={setDeleteCharger}
          text="Are you sure you want delete?"
        />
      )}
      {addCharger && (
        <AddCharger
          handleConfirm={handleConfirmAddCharger}
          close={() => setAddCharger(false)}
        />
      )}
      {showAddedChargerToast && (
        <Toast
          text="Charger successfully added to your account."
          positive={true}
          onClose={() => setShowAddedChargerToast(false)}
        />
      )}
      {showAddedChargerToastFail1 && (
        <Toast
          text="There is no registered charger with this ID and PIN. Check one more time or contact your provider."
          positive={false}
          onClose={() => setShowAddedChargerToastFail1(false)}
        />
      )}
      {showStoppedChargingToast && (
        <Toast
          text="Charging session is successfully stopped"
          positive={true}
          onClose={() => setShowAddedChargerToast(false)}
        />
      )}
    </div>
  );
});

export default Layout;
