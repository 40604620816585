import { ClipLoader } from "react-spinners";

const MySpinner = () => {
  return (
    <div className="fixed left-[37.5%] top-[30%] w-[25%] h-[30%] bg-transparent flex flex-col justify-center items-center z-[1200]">
      <ClipLoader size={65} color="green" />
    </div>
  );
};

export default MySpinner;
