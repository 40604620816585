interface Props {
  status: string;
}

const SessionStatus = ({ status }: Props) => {
  return (
    <div
      className={`flex justify-center items-center w-[72px] h-6 text-[12px] font-semibold rounded-[10px] border-[2px] px-2 py-1 ${
        status === "Active"
          ? "border-[#80C042] text-[#80C042] bg-[#F2F9EC]"
          : "text-[#ADADAD] border-[#ADADAD] bg-[#F7F7F7]"
      }`}
    >
      {status}
    </div>
  );
};
export default SessionStatus;
