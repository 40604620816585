export function formatTime(time: string, secundesIncluded: boolean) {
  let splited = time.split(":", secundesIncluded ? 3 : 2);
  for (let i = 0; i < splited.length; i++) {
    splited[i] = splited[i].split(".")[0];
  }
  return splited.join(":");
}

export const formatDateLocale = (
  dateString: string,
  locale = navigator.language
) => {
  let formattedDate = dateString.replaceAll("-", "/").split("T")[0];
  const date = new Date(formattedDate);

  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };
  let localeDateString = new Intl.DateTimeFormat(locale, options).format(date);

  localeDateString = localeDateString.replaceAll(".", "/").replaceAll("-", "/");
  localeDateString = localeDateString
    .replaceAll(" /", "/")
    .replaceAll("/ ", "/");

  if (localeDateString.endsWith("/")) {
    localeDateString = localeDateString.slice(0, -1);
  }

  return localeDateString;
};

export const convertUTCDateTimeToLocale = (utcTimeString: string) => {
  let date: Date;
  utcTimeString.slice(-1) === 'Z' ? date = new Date(utcTimeString) : date = new Date(utcTimeString + 'Z');
  const time = date.getTime();
  const utcDate = new Date(time);
  const localTime = time - utcDate.getTimezoneOffset() * 60 * 1000
  const localDate = new Date(localTime)
  const localIso = localDate.toISOString();
  return localIso;
};
