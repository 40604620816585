interface Props {
  handleClick: () => void;
}

const AddChargerButton = ({ handleClick }: Props) => {
  return (
    <div
      onClick={handleClick}
      style={{ boxShadow: "0px 4px 6.6px 0px rgba(0, 0, 0, 0.15)" }}
      className="w-[70px] h-[70px] bg-light-green rounded-full flex items-center justify-center"
    >
      <img src="/assets/svg/plus/plus.svg" alt="" />
    </div>
  );
};
export default AddChargerButton;
