import { createBrowserRouter, Navigate, RouteObject } from "react-router-dom";
import Login from "../../pages/Login/Login";
import ResetPassword from "../../pages/Login/ResetPassword";
import Layout from "../layout/layout";
import CreateAccount from "../../pages/CreateAccount/CreateAccount";
import ConfirmAccount from "../../pages/CreateAccount/ConfirmAccount";
import SetNewPassword from "../../pages/Login/SetNewPassword";
import ProtectedRoute from "./ProtectedRoutes";

export const routes: RouteObject[] = [
  { path: "/", element: <Navigate to="/login" replace /> },
  { path: "/login", element: <Login /> },
  { path: "/create-account", element: <CreateAccount /> },
  { path: "/verify-email/:code/:userId", element: <ConfirmAccount /> },
  { path: "/forgot-password", element: <ResetPassword /> },
  { path: "/reset-password/:code/:userId", element: <SetNewPassword /> },
  {
    path: "/dashboard",
    element: <ProtectedRoute element={<Layout />} />,
  },
];

export const router = createBrowserRouter(routes);
