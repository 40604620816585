import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import UserStore from "./userStore";
import ChargerStore from "./chargerStore";
import SessionStore from "./sessionStore";
import DemandShiftingStore from "./demandShiftingStore";
import SignalRStore from "./signalRStore";

interface Store {
  commonStore: CommonStore;
  userStore: UserStore;
  chargerStore: ChargerStore;
  sessionStore: SessionStore;
  demandShiftingStore: DemandShiftingStore;
  signalRStore: SignalRStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  chargerStore: new ChargerStore(),
  sessionStore: new SessionStore(),
  demandShiftingStore: new DemandShiftingStore(),
  signalRStore: new SignalRStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
