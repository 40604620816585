import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  HistorySession,
  HomeSessionDto,
  UpdateActiveSessionDto,
} from "../models/session";
import {
  convertUTCDateTimeToLocale,
  formatDateLocale,
  formatTime,
} from "../../utils/time/formatDateLocale";
import { roundOnFixedDecimals } from "../../utils/numbers/numbersHelper";
import moment from "moment";

export default class SessionStore {
  homeSessions: HomeSessionDto[] | undefined;
  historySessions: HistorySession[] | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  getHomeSessions = async () => {
    try {
      const res = await agent.Session.homeSessions();
      const mappedData: HomeSessionDto[] = res.map((el: any) => ({
        ...el,
        timeStart:
          formatDateLocale(convertUTCDateTimeToLocale(el.timeStart)) +
          " " +
          formatTime(convertUTCDateTimeToLocale(el.timeStart).split("T")[1], true),
        timeStop: el.timeStop
          ? formatDateLocale(convertUTCDateTimeToLocale(el.timeStop)) +
          " " +
          formatTime(convertUTCDateTimeToLocale(el.timeStop).split("T")[1], true)
          : "",
        duration: formatTime(el.duration, true),
        electricityConsumed: el.electricityConsumed
          ? roundOnFixedDecimals(el.electricityConsumed, 1) + " kWh"
          : "",
        chargerPower: el.chargerPower
          ? roundOnFixedDecimals(el.chargerPower, 1) + " kW"
          : "",
        carBattery: Math.round(el.carBattery) + "%" || "",
      }));
      runInAction(() => (this.homeSessions = mappedData));
    } catch (err) {
      console.log("Error while fetching home sessions: ", err);
    }
  };

  getHistoySessions = async () => {
    try {
      const res = await agent.Session.historySessions();
      const mappedData: HistorySession[] = res.map((el: any) => ({
        ...el,
        timeStart:
          formatDateLocale(convertUTCDateTimeToLocale(el.timeStart)) +
          " " +
          formatTime(convertUTCDateTimeToLocale(el.timeStart).split("T")[1], true),
        timeStop: el.timeStop
          ? formatDateLocale(convertUTCDateTimeToLocale(el.timeStop)) +
          " " +
          formatTime(convertUTCDateTimeToLocale(el.timeStop).split("T")[1], true)
          : "",
        duration: formatTime(el.duration, true),
        electricityConsumed: el.electricityConsumed
          ? roundOnFixedDecimals(el.electricityConsumed, 1) + " kWh"
          : "",
      }));
      runInAction(() => (this.historySessions = mappedData));
    } catch (err) {
      console.log("Error while fetching history sessions: ", err);
    }
  };

  stopSession = async (id: number) => {
    try {
      await agent.Session.stopSession(id);
      return true;
    } catch (err) {
      console.log("Error while stopping session: ", err);
      return false;
    }
  };

  updateActiveSession = async (data: UpdateActiveSessionDto) => {
    try {
      await agent.Session.updateActiveSession(data);
      return true;
    } catch (err) {
      console.log("Error while updating active session: ", err);
      return false;
    }
  };

  durationClockTick = () => {
    let newData = this.homeSessions?.map((el) => {
      if (el.sessionStatus === "Active") {
        const dur = moment.duration(el.duration).add(1, "second");
        return {
          ...el,
          duration: moment.utc(dur.asMilliseconds()).format("HH:mm:ss"),
        };
      } else return el;
    });
    runInAction(() => {
      this.homeSessions = newData;
    });
  };

  hasActiveSessions = () => {
    const activeSession = this.homeSessions?.find((el) => el.sessionStatus === "Active");
    return activeSession ? true : false;
  }
}
