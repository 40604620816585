import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Popup from "../../components/Popup/Popup";
import { RegisterUserDto } from "../../app/models/user";
import MySpinner from "../../components/Spinner/MySpinner";
import { useStore } from "../../app/stores/store";

const CreateAccount = () => {
  const { userStore, commonStore } = useStore();
  const navigate = useNavigate();
  const [visibleNewPassword, setVisibleNewPassword] = useState(false);
  const [visibleRepeatedPassword, setVisibleRepeatedPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailValidator, setEmailValidator] = useState(false);
  const [passwordValidator, setPasswordValidator] = useState(false);
  const [repeatedPasswordValidator, setRepeatedPasswordValidator] =
    useState(false);
  const [registerActive, setRegisterActive] = useState(false);
  const [touched, setTouched] = useState({
    firstName: false,
    lastName: false,
    email: false,
    password: false,
    repeatPassword: false,
  });

  const [userData, setUserData] = useState<RegisterUserDto>({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  useEffect(() => {
    if (
      userData.firstName &&
      userData.lastName &&
      userData.email &&
      userData.password &&
      userData.repeatPassword &&
      userData.password === userData.repeatPassword &&
      userData.password.length >= 6
    )
      setRegisterActive(true);
    else setRegisterActive(false);
  }, [userData]);

  useEffect(() => {
    const { email, password, repeatPassword } = userData;
    validateEmail(email) ? setEmailValidator(true) : setEmailValidator(false);
    password.length > 5
      ? setPasswordValidator(true)
      : setPasswordValidator(false);
    password.length > 5 && password === repeatPassword
      ? setRepeatedPasswordValidator(true)
      : setRepeatedPasswordValidator(false);
  }, [userData, userData.email, userData.password, userData.repeatPassword]);

  const validateEmail = (email: string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name } = e.target;
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));
  };

  const handleRegister = async () => {
    setLoading(true);
    try {
      await userStore.register(userData).then(() => {
        setShowPopup(true);
      });
    } catch (error) {
      console.error("Login failed", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="relative flex flex-col justify-between bg-white px-8 pb-8 pt-[100px] text-white h-screen ">
      <img
        className="absolute top-10 left-7"
        src="/assets/svg/arrow/arrowBack.svg"
        alt="back"
        onClick={() => navigate(-1)}
      />
      <div className="flex flex-col gap-4 w-full">
        <p className="text-[18px] font-semibold text-dark-green mb-4">
          Welcome to EnergAI!
        </p>
        <div className="flex flex-col gap-1">
          <label className="text-[14px] font-medium text-dark-green mb-1">
            First Name
          </label>
          <div>
            <input
              className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
              type="text"
              placeholder="First Name"
              name="firstName"
              value={userData.firstName}
              onChange={handleInputChange}
              onBlur={handleBlur} // Track blur event
            />
          </div>
          {touched.firstName && !userData.firstName ? (
            <p className="text-error-red text-[10px]">First name is required</p>
          ) : (
            <div className="h-[15px]" />
          )}
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-[14px] font-medium text-dark-green mb-1">
            Last Name
          </label>
          <div>
            <input
              className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
              type="text"
              placeholder="Last Name"
              name="lastName"
              value={userData.lastName}
              onChange={handleInputChange}
              onBlur={handleBlur} // Track blur event
            />
          </div>
          {touched.lastName && !userData.lastName ? (
            <p className="text-error-red text-[10px]">Last name is required</p>
          ) : (
            <div className="h-[15px]" />
          )}
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-[14px] font-medium text-dark-green mb-1">
            Email
          </label>
          <div className="relative">
            <input
              className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
              type="text"
              placeholder="Email"
              name="email"
              value={userData.email}
              onChange={handleInputChange}
              onBlur={handleBlur} // Track blur event
            />
            {emailValidator && (
              <img
                src="/assets/svg/tick/green-tick.svg"
                alt=""
                className="absolute cursor-pointer top-0 right-[10px]"
              />
            )}
          </div>
          {touched.email && !userData.email ? (
            <p className="text-error-red text-[10px]">Email is required</p>
          ) : (
            <div className="h-[15px]" />
          )}
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-[14px] font-medium text-dark-green mb-1">
            Password
          </label>
          <div className="relative">
            <input
              className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
              type={visibleNewPassword ? "text" : "password"}
              placeholder="Password"
              name="password"
              value={userData.password}
              onChange={handleInputChange}
              onBlur={handleBlur} // Track blur event
            />
            {passwordValidator && (
              <img
                src="/assets/svg/tick/green-tick.svg"
                alt=""
                className="absolute cursor-pointer top-0 right-[10px]"
              />
            )}
          </div>
          {touched.password && !userData.password ? (
            <p className="text-error-red text-[10px]">
              Password must be at least 6 characters long
            </p>
          ) : (
            <div className="h-[15px]" />
          )}
        </div>

        <div className="flex flex-col gap-1">
          <label className="text-[14px] font-medium text-dark-green mb-1">
            Confirm Password
          </label>
          <div className="relative">
            <input
              className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
              type={visibleRepeatedPassword ? "text" : "password"}
              placeholder="Confirm Password"
              name="repeatPassword"
              value={userData.repeatPassword}
              onChange={handleInputChange}
              onBlur={handleBlur} // Track blur event
            />
            {repeatedPasswordValidator && (
              <img
                src="/assets/svg/tick/green-tick.svg"
                alt=""
                className="absolute cursor-pointer top-0 right-[10px]"
              />
            )}
          </div>
          {touched.repeatPassword && !userData.repeatPassword ? (
            <p className="text-error-red text-[10px]">
              Confirm password is required
            </p>
          ) : (
            touched.repeatPassword &&
            userData.password !== userData.repeatPassword && (
              <p className="text-error-red text-[10px]">Passwords must match</p>
            )
          )}
        </div>
      </div>
      <button
        className={`w-full mt-4 h-12 rounded-[10px] text-[12px] font-semibold ${
          registerActive
            ? "bg-light-green text-white"
            : "text-light-gray bg-lighter-gray"
        }`}
        disabled={!registerActive}
        onClick={handleRegister}
      >
        Register
      </button>
      {showPopup && <Popup handleClose={() => setShowPopup(false)} />}
      {loading && <MySpinner />}
    </div>
  );
};

export default CreateAccount;
