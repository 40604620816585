import { useState, useEffect, useRef } from "react";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import { ClipLoader } from "react-spinners";

interface Props {
  firstName: boolean;
  close: () => void;
}

const UpdateNameModal = observer(({ close, firstName }: Props) => {
  const { userStore } = useStore();
  const { user } = userStore;

  const initialNameValue = firstName ? user?.firstName : user?.lastName;
  const [value, setValue] = useState(initialNameValue);
  const [isLoading, setIsLoading] = useState(false);
  const [translateY, setTranslateY] = useState(0);
  const [isClosing, setIsClosing] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const isFormValid = value !== initialNameValue && value!.trim() !== "";

  const handleClearInput = () => {
    setValue("");
    inputRef.current?.focus();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const text = firstName ? "Name" : "Surname";

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      close();
    }, 150);
  };

  const handleConfirmEditName = async () => {
    setIsLoading(true);
    if (value) {
      if (firstName) {
        await userStore.updateUserName(value, user?.lastName!);
      } else {
        await userStore.updateUserName(user?.firstName!, value);
      }
      setIsClosing(true);
      close();
      await userStore.getUserInfo();
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        aria-hidden="true"
        className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-[1px] z-9999"
      ></div>

      <div
        className="flex flex-col gap-7 rounded-t-[10px] fixed pt-12 pb-8 px-5 z-1000 bg-white bottom-0 left-0 right-0 w-full"
        style={{
          transition: isClosing
            ? "transform 0.3s ease-in"
            : translateY === 0
            ? "transform 0.3s ease"
            : "none",
          transform: isClosing
            ? "translateY(100%)"
            : `translateY(${translateY}px)`,
          overscrollBehavior: "none",
        }}
      >
        <img
          onClick={handleClose}
          className="absolute top-4 right-6"
          width={16}
          height={16}
          src="/assets/svg/exit/cross.svg"
          alt=""
        />
        <div className="flex flex-col gap-2 relative w-full">
          <label className="font-bold text-dark-green">{text}</label>
          <input
            ref={inputRef}
            className="text-[14px] font-medium w-full text-light-gray border-b-2 border-[#F7F7F7] py-1 focus:outline-none"
            type="text"
            value={value}
            onChange={handleInputChange}
          />
          <img
            onClick={handleClearInput}
            className="absolute top-[28px] right-4"
            src="/assets/svg/crossdelete/circle.svg"
            alt=""
          />
        </div>
        <button
          className={`flex h-12 items-center justify-center text-[14px] w-full font-bold px-5 rounded-[5px] transition duration-200 ${
            isFormValid
              ? "bg-[#00A991] text-white"
              : "bg-[#D6D6D6] text-[#515151]"
          }`}
          disabled={!isFormValid}
          onClick={handleConfirmEditName}
        >
          {isLoading ? <ClipLoader size={24} color="white" /> : "Save Changes"}
        </button>
      </div>
    </>
  );
});

export default UpdateNameModal;
