import { useState } from "react";

interface Props {
  text: string;
  close: (value: boolean) => void;
  handleConfirm: () => void;
}

const Modal = ({ text, close, handleConfirm }: Props) => {
  const [translateY, setTranslateY] = useState(0);
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      close(false);
    }, 150);
  };

  return (
    <>
      <div
        aria-hidden="true"
        className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-[1px] z-9999"
      ></div>

      <div
        className="flex flex-col gap-7 rounded-t-[10px] items-center fixed pt-6 pb-8 px-5 z-1000 bg-white bottom-0 left-0 right-0 w-full"
        style={{
          transition: isClosing
            ? "transform 0.3s ease-in"
            : translateY === 0
            ? "transform 0.3s ease"
            : "none",
          transform: isClosing
            ? "translateY(100%)"
            : `translateY(${translateY}px)`,
          overscrollBehavior: "none",
        }}
      >
        <p className="font-bold text-dark-green">{text}</p>
        <div className="flex gap-5 w-full">
          <button
            onClick={handleClose}
            className="text-[#ADADAD] w-full h-9 text-[12px] font-semibold rounded-[5px] bg-[#F7F7F7]"
          >
            Cancel
          </button>
          <button
            onClick={handleConfirm}
            className="text-white h-9 w-full text-[12px] font-semibold rounded-[5px] bg-light-green"
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default Modal;
