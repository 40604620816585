import { useEffect, useRef, useState } from "react";
import DateSelect from "../DateSelect/DateSelect";
import { hours, minutes } from "./timeHelper";

interface Props {
  close: (value: boolean) => void;
  sessionId: number;
  handleConfirm: (
    id: number,
    batteryCapacity: number,
    CurrentSoC: number,
    stopDatetime: string
  ) => void;
}
type Calendar = {
  startDate: string | null;
  endDate: string | null;
};

const EditActive = ({ close, sessionId, handleConfirm }: Props) => {
  const today = new Date();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const [calendar, setCalendar] = useState<Calendar>({
    startDate: today.toISOString(),
    endDate: today.toISOString(),
  });
  const [startY, setStartY] = useState<number | null>(null);
  const [showDateModal, setShowDateModal] = useState(false);
  const [batteryCapacity, setBatteryCapacity] = useState("");
  const [currentStateOfCharge, setCurrentStateOfCharge] = useState("");
  const [selectedStopTime, setSelectedStopTime] = useState({
    hours: "",
    minutes: "",
    date: today.toISOString(),
    dateISO: today.toISOString(),
  });
  const [selectedTime, setSelectedTime] = useState({
    hours: new Date().getHours().toString(),
    minutes: new Date().getMinutes().toString(),
  });

  const [translateY, setTranslateY] = useState(0);
  const [isClosing, setIsClosing] = useState(false);

  const isFormValid =
    batteryCapacity.trim() !== "" &&
    currentStateOfCharge.trim() !== "" &&
    selectedStopTime.hours !== "" &&
    selectedStopTime.minutes !== "";

  const handleApplyClick = () => {
    const date = calendar.startDate ?? today.toISOString();
    const newDate = new Date(date);
    const formattedDate = `${String(newDate.getDate()).padStart(
      2,
      "0"
    )}/${String(newDate.getMonth() + 1).padStart(
      2,
      "0"
    )}/${newDate.getFullYear()}`;

    newDate.setHours(
      Number(selectedTime.hours),
      Number(selectedTime.minutes),
      0,
      0
    );

    setSelectedStopTime({
      hours: selectedTime.hours,
      minutes: selectedTime.minutes,
      date: formattedDate,
      dateISO: newDate.toISOString(),
    });
    setShowDateModal(false);
  };

  const handleHourSelect = (hours: string) => {
    setSelectedTime((prev) => ({ ...prev, hours: hours }));
  };
  const handleMinutesSelect = (minutes: string) => {
    setSelectedTime((prev) => ({ ...prev, minutes: minutes }));
  };

  const period = selectedTime.hours < "12" ? "AM" : "PM";
  const selectedDateTime =
    selectedStopTime.hours !== ""
      ? `${selectedStopTime.hours}:${selectedStopTime.minutes}   ${selectedStopTime.date}`
      : "";

  const handleSelectedDate = (value: any) => {
    setCalendar({ startDate: value, endDate: value });
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowDateModal(false);
      }
    };

    if (showDateModal) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [showDateModal]);

  const handleClose = () => {
    setIsClosing(true);
    setTimeout(() => {
      close(false);
    }, 150);
  };

  return (
    <>
      <div
        aria-hidden="true"
        className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-[1px] z-0"
      ></div>

      <div
        className="flex flex-col rounded-t-[10px] items-center fixed pt-12 pb-8 px-5 z-1000 bg-white bottom-0 left-0 right-0"
        style={{
          transition: isClosing
            ? "transform 0.3s ease-in"
            : translateY === 0
            ? "transform 0.3s ease"
            : "none",
          transform: isClosing
            ? "translateY(100%)"
            : `translateY(${translateY}px)`,
          overscrollBehavior: "none",
        }}
      >
        <img
          onClick={handleClose}
          className="absolute top-4 right-6"
          width={16}
          height={16}
          src="/assets/svg/exit/cross.svg"
          alt=""
        />
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center w-full gap-3">
            <div className="flex flex-col gap-2.5 w-2/5">
              <label className="text-[13px] font-semibold text-dark-green">
                Battery capacity
              </label>
              <div className="relative">
                <input
                  value={batteryCapacity}
                  onChange={(e) => setBatteryCapacity(e.target.value)}
                  className="text-[12px] font-medium text-[#000] rounded-[5px] px-2 py-2 border-2 border-[#F7F7F7] focus:outline-none w-full"
                  type="number"
                />
                <p className="text-[12px] font-medium absolute cursor-pointer top-[11px] right-2.5">
                  kWh
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2.5 w-3/5">
              <label className="text-[13px] font-semibold text-dark-green">
                Current state of charge
              </label>
              <div className="relative">
                <input
                  value={currentStateOfCharge}
                  onChange={(e) => setCurrentStateOfCharge(e.target.value)}
                  className="text-[12px] font-medium text-[#000] rounded-[5px] px-2 py-2 border-2 border-[#F7F7F7] focus:outline-none w-full"
                  type="number"
                />
                <p className="text-[14px] font-medium absolute cursor-pointer top-[9px] right-3">
                  %
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2.5 w-full mb-6">
            <label className="text-[13px] font-semibold text-dark-green">
              Preferred charging end time
            </label>
            <div className="relative w-full">
              <input
                readOnly
                value={selectedDateTime}
                onFocus={() => setShowDateModal(true)}
                className={`text-[12px] font-medium text-[#000] rounded-[5px] px-2 py-2 border-2 border-[#F7F7F7] focus:outline-none w-full ${
                  showDateModal ? "border-[#00A991]" : ""
                }`}
              />
              {showDateModal && (
                <div
                  className="flex gap-1 shadow-calendar-shadow w-full"
                  ref={modalRef}
                >
                  <div className="flex flex-col p-1 w-1/3">
                    <div className="flex pl-1 pt-2.5 pb-1 border-b-2 border-[#F7F7F7] justify-between">
                      <p className="text-[12px] font-bold text-[#333]">
                        {selectedTime.hours}:{selectedTime.minutes} {period}
                      </p>
                      <img src="/assets/svg/x/x.svg" alt="" />
                    </div>
                    <div className="flex h-full pt-5 justify-center">
                      <div className="flex items-baseline gap-6  h-[170px]">
                        <div className="flex h-full flex-col gap-1.5 overflow-y-auto">
                          {hours.map((hour, index) => (
                            <p
                              key={index}
                              onClick={() => handleHourSelect(hour)}
                              className={`flex justify-center items-center text-[10px] p-1  ${
                                selectedTime.hours === hour
                                  ? "bg-light-green rounded-full p-0.5 text-white font-semibold"
                                  : "text-[#333] font-medium"
                              }`}
                            >
                              {hour}
                            </p>
                          ))}
                        </div>
                        <div className="flex h-full flex-col gap-1.5 overflow-y-auto">
                          {minutes.map((minute, index) => (
                            <p
                              key={index}
                              onClick={() => handleMinutesSelect(minute)}
                              className={`flex justify-center items-center text-[10px] p-1  ${
                                selectedTime.minutes === minute
                                  ? "bg-light-green rounded-full p-0.5 text-white font-semibold"
                                  : "text-[#333] font-medium"
                              }`}
                            >
                              {minute}
                            </p>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <img src="/assets/svg/line/line.svg" alt="" />
                  <div className="w-2/3 px-2 py-3 pt-0">
                    <DateSelect
                      start={calendar.startDate}
                      end={calendar.endDate}
                      setSelected={handleSelectedDate}
                      demandShift={true}
                    />
                    <div className="flex justify-end gap-2 items-center w-[100%] p-1.5">
                      <button
                        className="h-[26px] w-[100px] rounded-[5px] bg-light-green text-white text-[12px] font-medium"
                        onClick={handleApplyClick}
                      >
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <button
          onClick={() =>
            handleConfirm(
              sessionId,
              Number(batteryCapacity),
              Number(currentStateOfCharge),
              selectedStopTime.dateISO
            )
          }
          className={`text-[14px] w-full font-bold py-2 px-5 rounded-[5px] transition duration-200 ${
            isFormValid
              ? "bg-[#00A991] text-white"
              : "bg-[#D6D6D6] text-[#515151]"
          }`}
          disabled={!isFormValid}
        >
          Confirm
        </button>
      </div>
    </>
  );
};

export default EditActive;
