import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { useEffect } from "react";

interface Props {
  handleEditFirstName: () => void;
  handleEditLastName: () => void;
  handleEditPassword: () => void;
}
const Profile = observer(
  ({ handleEditFirstName, handleEditLastName, handleEditPassword }: Props) => {
    const { userStore, commonStore } = useStore();
    const { user } = userStore;
    const password = "•".repeat(10);

    const handleLogout = () => {
      try {
        if (commonStore.isLoggedIn()) {
          userStore.logout();
        }
      } catch (error) {
        console.log("Error logging out");
      }
    };
    return (
      <>
        <div className="text-center">
          <p className="text-[20px] font-bold text-dark-green mt-2 mb-10">
            Account info
          </p>
        </div>
        <div className="flex flex-col gap-6 w-full">
          <p className="font-bold text-dark-green">Basic info</p>
          <div className="flex flex-col gap-4">
            <div
              onClick={handleEditFirstName}
              className="flex justify-between text-[14px] font-medium text-light-gray py-1 w-full border-b-2 border-[#F7F7F7]"
            >
              {user?.firstName}
              <img src="/assets/svg/arrow/arrowRight.svg" alt="" />
            </div>
            <div
              onClick={handleEditLastName}
              className="flex justify-between text-[14px] font-medium text-light-gray py-1 w-full border-b-2 border-[#F7F7F7]"
            >
              {user?.lastName}
              <img src="/assets/svg/arrow/arrowRight.svg" alt="" />
            </div>
            <div className="flex justify-between text-[14px] font-medium text-light-gray py-1 w-full border-b-2 border-[#F7F7F7]">
              {user?.email}
            </div>
          </div>
          <p className="font-bold text-dark-green mt-3">Password</p>
          <div
            onClick={handleEditPassword}
            className="flex justify-between text-[24px] font-semibold text-dark-green py-1 w-full border-b-2 border-[#F7F7F7]"
          >
            {password}
            <img src="/assets/svg/arrow/arrowRight.svg" alt="" />
          </div>
          <div
            onClick={handleLogout}
            className="mt-6 text-[14px] font-semibold text-[#DA3333]"
          >
            Log out
          </div>
        </div>
      </>
    );
  }
);
export default Profile;
