import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import MySpinner from "../../components/Spinner/MySpinner";

const ConfirmAccount = () => {
  const { userStore, commonStore } = useStore();
  const { code, userId } = useParams();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [visiblePassword, setVisiblePassword] = useState(false);
  const [touched, setTouched] = useState(false);

  const navigate = useNavigate();

  const handleBlur = () => {
    setTouched(true);
  };

  const handleConfirm = () => {
    setLoading(true);
    if (password) {
      userStore
        .verifyEmail({
          userId: Number(userId),
          activationCode: code,
          password: password,
        })
        .then(() => {
          if (commonStore.isLoggedIn()) navigate("/dashboard");
          setLoading(false);
        });
    }
  };

  const isPasswordValid = password.length >= 6;

  return (
    <div className="relative flex flex-col gap-10 justify-center bg-white px-8 pb-8 pt-[100px] text-white h-screen ">
      <img
        className="absolute top-10 left-7"
        src="/assets/svg/arrow/arrowBack.svg"
        alt="back"
        onClick={() => navigate(-1)}
      />
      <div>
        <p className="text-[18px] py-0 font-semibold text-dark-green">Hi</p>
        <p className="text-[18px] my-0 font-semibold text-[#8A8A8A]">
          Start your EnergAI journey from here!
        </p>
      </div>

      <div className="flex flex-col gap-2">
        <div className="relative">
          <input
            className="w-full py-1 text-dark-green text-[12px] focus:outline-none border-b-[2px] border-solid placeholder:text-[12px] border-[#F7F7F7]"
            type={visiblePassword ? "text" : "password"}
            placeholder="Password"
            name="confirmPassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onBlur={handleBlur}
          />
          <img
            src={
              visiblePassword
                ? "/assets/svg/eye/eye-open.svg"
                : "/assets/svg/eye/eye-closed.svg"
            }
            alt="toggle visibility"
            className="absolute cursor-pointer top-1 right-[10px]"
            onClick={() => setVisiblePassword(!visiblePassword)}
          />
        </div>
        {touched && password.length < 6 && (
          <p className="text-error-red text-[10px]">
            Password must be at least 6 characters long
          </p>
        )}
      </div>

      <button
        onClick={handleConfirm}
        className={`bg-light-green w-full mt-4 h-12 rounded-[10px] text-white text-[12px] font-semibold ${
          !isPasswordValid ? "opacity-50" : ""
        }`}
        disabled={!isPasswordValid}
      >
        Confirm and Login
      </button>
      {loading && <MySpinner />}
    </div>
  );
};

export default ConfirmAccount;
